@import "rsuite/dist/rsuite.css";

.meterdatamain {
    min-height: calc(100vh - 40px);
}

.machinetabledata {
    margin: 1rem;
    font-size: 0.8rem;
}
.datepiker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.rangepiker {
    width: 14rem;
}

.guageChartcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-top: -2rem; */
}

.btnreport {
    background-color: transparent;
    border: 1px solid #3e4492;
    padding: 2px 10px;
    border-radius: 5px;
    margin: 0 0.5rem;
    width: 4rem;
    margin: 0.3rem 0rem;
    /* align-self: center; */
    font-size: 0.7rem;
    margin-left: 2rem;

}

.btnreport:hover {
    border: 1px solid #3e4492;
    color: white;
    background-color: #3e4492;
}
.allcharts{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gaugeName{
    color: blue;
}

.linechartcontainer {
    display: flex;
    margin-top: 1rem;
    /* margin-left: 2rem;  */
    height: 35vh;
    align-self: flex-start;
    /* margin: auto; */
}
.gaugechartarea1{
    display: flex;
}
.gaugechartarea2{
    display: flex;
}
.gaugechartarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.getherCharts {
    display: flex;
    flex-direction: column;
    /* margin-top: -1rem; */
}
.temppowergraph{
    display: flex;
    justify-content: space-between;
    margin-top: 0rem;
}
.temppowergraph .temppower{
    margin-top: -6rem;
    height: 17rem;
    width: 13rem;
}
.gaugechartpowerfuel{
    display: flex;
    flex-direction: row;
}


@media screen and (max-width:800px) {
    .getherCharts {
        flex-direction: column;
    }
    .guageChartcontainer{
        flex-direction: row;
    }
    .linechartcontainer{
        width: 100%;
        height: 40vh;
        margin-bottom: 2rem;
    }
    .guageChartcontainer{
        flex-direction: column;
    }
    .chartarea3{
        width: 100%;
    }
}